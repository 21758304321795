<template>
    <!--政策法规-->
    <div class="base-container">
        <div class="main-wrapper">
            <el-container style="height: 100%; border: 1px solid #eee; background:#fff">

                <el-header>
                    <div class="header">
                        <div class="search_box">
                            <p class="header_title">政策法规查询</p>
                            <el-input class="search-content" prefix-icon="el-icon-search" size="small" v-model="keyword" placeholder="输入关键字，搜索法规" ></el-input>
                            <el-button class="search_btn" type="primary" size="small" @click="searchClick()">搜索</el-button>
                        </div>
                        <div class="hot_box">
                            <span class="search_title">热门搜索:</span>
                            <span class="search_tag" v-for="(item,index) in hotWordArr" :key="index" @click="searchClick(item.name)">{{item.name}}</span>
                            
                            <span class="search_tag" v-show="isMore" @click="moreClick()">更多</span>
                        </div>
                        
                    </div>
                </el-header>
                <el-container>
                <el-aside width="250px" style="height:684px;background-color: rgb(255, 255, 255)">
                    <el-menu :default-openeds="open" :default-active="navPath">
                        <div class="all">全部政策法规</div>
                        <el-submenu :index="index+1+''" v-for="(item,index) in themeList" :key="index">
                            <template slot="title" ><span :title="item.themeName" >{{item.themeName}}</span></template>
                            <el-menu-item :index="index+1+'-1'" @click="getThemeLaw(item.id, 1, index+1+'-1','zy')">中央法规</el-menu-item>
                            <el-menu-item :index="index+1+'-2'" @click="getThemeLaw(item.id, 2, index+1+'-2','df')">地方法规</el-menu-item>
                        </el-submenu>
                  
                    </el-menu>
                </el-aside>
                    <el-main>
                        <div class="chart_box">
                            <div class="table-box">
                                <RegulationTable :tableData="tableData" :loading="loading"></RegulationTable>
                            </div>
                            <div class="page-box">
                                <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    background
                                    layout="prev,pager,next,total,sizes,jumper"
                                    :page-sizes="[20, 50, 100]"
                                    :total="total"
                                    :current-page="pageNum">
                                </el-pagination>
                            </div>
                        </div>
                    </el-main>
                </el-container>
            </el-container>
        </div>
    </div>
</template>

<script>

import {getStore,setStore,getLocalStore} from "@/util/store";
import {mapMutations} from "vuex";
import regulationTable from '@/views/policiesRegulation/table.vue'

export default {
    name: "index",
    components: {
        RegulationTable:regulationTable
    },

    data() {
        return {
          themeList:[],
          tableData:[],
          keyword:'',
          tableHeight:'',
          loading: false,
          navPath:'1-1',
          open:['0'],
          total:0,
          pageNum:1,
          themeId:'',
          lawType:'',
          pageSize:20,
          isSearch:false,
          hotWordArr: [],
          hotWordArrMore: [],
          isMore:true,
        }
    },
    created() {
        // if(this.$route.query.keyword){
        //     this.keyword = this.$route.query.keyword;
        //     this.isSearch = true;
        //     this.getSearchData()
        // }
        this.getReport(0,'','');
        this.getHotWord();
        if(getStore('navPathFlag')){
            this.navPath = getStore('navPath');
            this.getTheme(true);
        }else{
            setStore('navPath',this.navPath);
            this.getTheme(false);

        }
        setStore('navPathFlag',false);
        
    },
    destroyed(){
        this.getReport(1,'','');
    },
    mounted() {
    },
    methods: {
        ...mapMutations(["reducePath", "addMenuList", "reduceMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policiesRegulation',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        //获取主题浏览菜单
        getTheme(flag){
            this.axios.request({
                loading: true,
                method: "get", 
                url: '/dms/law/theme',
                 
            }).then((res) => {
                this.themeList = res.data.data;
                if(flag){
                    this.getThemeLaw(getStore('navPathId'),getStore('navPathlawType'),this.navPath,'')
                }else{
                    this.getThemeLaw(res.data.data[0].id,1,'1-1','')
                }
                
            })
        },
        //获取法规数据
        getThemeLaw(themeId,lawType,navPath,type){
            if(type=='zy'){
                this.getReport(2,'nav','centralRregulations');
            }else if(type=='df'){
                this.getReport(2,'nav','localRregulation');
            }
            this.navPath = navPath;
            setStore('navPath',this.navPath);
            setStore('navPathId',themeId);
            setStore('navPathlawType',lawType);
            this.themeId =themeId;
            this.lawType =lawType;
            this.pageNum = 1;
            this.isSearch = false;

            this.getAxios();
        },
        getAxios(){
            this.loading = true;
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/law/pageListThemeLaw',
                 
                data:{
                    pageNum:this.pageNum,
                    pageSize:this.pageSize,
                    themeId:this.themeId,
                    lawType:this.lawType,
                    valid:''
                }
            }).then((res) => {
                this.tableData = [];
                this.total = res.data.data.total;
                this.tableData = res.data.data.records;
                this.loading = false;

            })
        },
     
        searchClick(keyword){
            if(keyword){
                this.keyword = keyword;
                this.getReport(2,'search','hotWord');
            }else{
                this.getReport(2,'search','searchBtn');
            }
            this.isSearch = true;
            this.pageNum = 1;
            this.getSearchData()
        },
        getSearchData(){
            this.loading = true;
            this.tableData = [];
            this.axios.request({
                loading: true,
                method: "post", 
                url: '/dms/law/pageLawByKeyword',
                 
                data:{
                    pageNum: this.pageNum,
                    pageSize: this.pageSize,
                    keyword: this.keyword,
                }
            }).then((res) => {
                this.total = res.data.data.total;
                this.tableData = res.data.data.records;
                this.loading = false;

            })
        },
        handleSizeChange(val){
            this.pageSize = val;
            this.pageNum = 1;
            if(this.isSearch){
                this.getSearchData();
            }else{
                this.getAxios()
            }
        },
        handleCurrentChange(val){
            this.getReport(2,'table',val);
            this.pageNum = val;
            if(this.isSearch){
                this.getSearchData();
            }else{
                this.getAxios()
            }
        },
        //获取热词
        getHotWord() {
            // var companyName = getStore('thisProject').taxpayerName;
            // var projectId = getStore('thisProject').id;
            this.axios({
                method: "get", url: '/dms/law/listTop10Keyword',
                 
                // params: {
                //     companyName: companyName,
                //     projectId: projectId,
                // },
            }).then((res) => {
                this.hotWordArrMore = res.data.data;
                this.hotWordArr = res.data.data.slice(0,3);
               
                
            }).catch((error) => {
                console.log(error)
            })
        },
        moreClick(){
            this.isMore = false;
            this.hotWordArr = this.hotWordArrMore;
            this.getReport(2,'search','hotWord');
        }
        
       
    }
}
</script>

<style scoped lang="less">
::-webkit-scrollbar {
    width: 0;
}
.el-header{
  height: 98px !important;  
  padding: 0;
  border-bottom: 1px solid #EBEBEB;
}
.header{
    width: 610px;
    margin: 0 auto;
    height: 98px;
    
}
.search_box{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.hot_box{
    margin-top: 10px;
    width: 100%;
    text-align: center;
}
.search_title{
    margin-right: 16px;
    font-size: 14px;
    color: #323233;
}
.search_tag{
    font-size: 14px;
    font-weight: 400;
    color: #185BCE;
    margin-right: 16px;
    cursor: pointer;
    display: inline-block;
}
.header_title{
    width: 141px !important;
    height: 22px;
    font-size: 16px;
    color: #185BCE;
    font-weight: 600;
    margin-right: 10px;
}
.search_btn{
    margin-left: 21px;
    width: 84px;
}
.main-wrapper{
    height: 100%;
}
.allMenu{
    font-weight: 600;
    color: #333333;
}
/deep/.el-submenu__title{
    font-size: 14px !important;
    font-weight: 600;
    color: #333333;
    -webkit-line-clamp: 1;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    white-space: normal;
}
/deep/.el-submenu__title i {
    color: #000000;
    font-weight: 600;
}
.el-menu-item.is-active{
    background: #EDF4FF !important;
    color: #185BCE !important;
}
.chart_box{
    width: 100%;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
}
.el-main{
    height: 684px;
    padding: 0 !important;
}

.table-box {
    flex: 1;
    display: flex;
    overflow: hidden;
}
.page-box{
    margin: 30px 0;
}
.all{
    // width: 249px;
    height: 56px;
    line-height: 56px;
    padding-left: 20px;
    font-size: 14px !important;
    font-weight: 600;
    color: #333333;
}
</style>