<template>
    <div style="width:100%">
        <el-table ref="table" width="910" :data="tableData" border stripe  :header-cell-style="{background:'#F6F6F6',color:'#333333'}"
                v-loading="loading" @row-click="openDetails">
            <el-table-column prop="title" label="标题" width="320" ></el-table-column>
            <el-table-column prop="publishDate" label="发布日期"></el-table-column>
            <el-table-column prop="organization" label="发布机关"></el-table-column>
            <el-table-column prop="lawNo" label="法规编号" width="220"></el-table-column>
            <el-table-column prop="valid" label="有效性"></el-table-column>
            <el-table-column label="操作" align="center" width="60">
                <template>
                    <el-button size="mini" type="text">详情</el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>

</template>

<script>

import {getStore,setStore,getLocalStore} from '@/util/store'
import {mapMutations} from 'vuex'
export default {
    props: {
        tableData:Array,
        loading:Boolean
    },
    data() {
        return {
        }
    },
    created(){
    },
    methods:{
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'policiesRegulation',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        openDetails(row){
            this.getReport(2,'table','detailBtn');
            setStore('navPathFlag',true);
            this.addPath(row.title);
            this.addMenuList("/detail");
            setStore("currentMenu", "/detail");
            this.$router.push({path: "/detail",query:{abstractId:row.abstractId,lawId:row.id}});
        }
    }
}
</script>

<style scoped  lang="less">
/deep/tbody{
    cursor: pointer;
}
</style>